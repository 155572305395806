.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  background-color: #FFF;
  padding-top: 1em;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--ad-dark);
}

.brand-primary {
  color: var(--ad-primary-shade-1);
  font-weight: 600;
}

.brand-secondary {
  color: var(--ad-danger-tint-1);
  font-weight: 900;
}

.App-link {
  color: #61dafb;
}

.App-intro {
  margin-bottom: 0!important;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.offscreen {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}