:root {
    /*  Dead Sea (primary)  */ 
    --ad-primary:         #72F2EB;
    --ad-primary-tint-1:  #80F3ED;
    --ad-primary-shade-1: #67DAD3;
    --ad-primary-tint-2:  #8EF5EF;
    --ad-primary-shade-2: #5BC2BC;
    --ad-primary-tint-3:  #9CF6F1;
    --ad-primary-shade-3: #50A9A4;
    --ad-primary-tint-4:  #AAF7F3;
    --ad-primary-shade-4: #44918D;
    --ad-primary-tint-5:  #B8F8F5;
    --ad-primary-shade-5: #397976;
    --ad-primary-disabled:#d8efee;
    --ad-primary-subtle:  #cffdfc;

    /* Secondary (Neutral Gray) */
    --ad-secondary:         #EBEBEB;
    --ad-secondary-tint-1:  #EDEDED;
    --ad-secondary-shade-1: #D3D3D3;
    --ad-secondary-tint-2:  #EFEFEF;
    --ad-secondary-shade-2: #BCBCBC;
    --ad-secondary-tint-3:  #F1F1F1;
    --ad-secondary-shade-3: #A4A4A4;
    --ad-secondary-tint-4:  #F3F3F3;
    --ad-secondary-shade-4: #8D8D8D;
    --ad-secondary-tint-5:  #F5F5F5;
    --ad-secondary-shade-5: #767676;
    --ad-secondary-disabled:#eeeeee;
    --ad-secondary-subtle:  #f3efef;

    --ad-dark:#262523;

    /*  Fei Hong Scarlet (secondary, danger)  */ 
    --ad-danger:         #FF3E0D;
    --ad-danger-tint-1:  #FF5125;
    --ad-danger-shade-1: #E6380C;
    --ad-danger-tint-2:  #FF653D;
    --ad-danger-shade-2: #CC320A;
    --ad-danger-tint-3:  #FF7856;
    --ad-danger-shade-3: #B22B09;
    --ad-danger-tint-4:  #FF8B6E;
    --ad-danger-shade-4: #992508;
    --ad-danger-tint-5:  #FF9E86;
    --ad-danger-shade-5: #801F06;
    --ad-danger-disabled:#e7cdc7;
    --ad-danger-subtle:  #fdd5c9;

    /*  Success  */ 
    --ad-success:         #00A676;
    --ad-success-tint-1:  #19AF84;
    --ad-success-shade-1: #00956A;
    --ad-success-tint-2:  #33B891;
    --ad-success-shade-2: #00855E;
    --ad-success-tint-3:  #4DC19F;
    --ad-success-shade-3: #007453;
    --ad-success-tint-4:  #66CAAD;
    --ad-success-shade-4: #006447;
    --ad-success-tint-5:  #80D2BB;
    --ad-success-shade-5: #00533B;
    --ad-success-disabled:#c9ede3;
    --ad-success-subtle:  #c9faed;

    /*  Warning  */ 
    --ad-warning:         #FFB300;
    --ad-warning-tint-1:  #FFBB19;
    --ad-warning-shade-1: #E6A100;
    --ad-warning-tint-2:  #FFC233;
    --ad-warning-shade-2: #CC8F00;
    --ad-warning-tint-3:  #FFCA4D;
    --ad-warning-shade-3: #B27D00;
    --ad-warning-tint-4:  #FFD166;
    --ad-warning-shade-4: #996B00;
    --ad-warning-tint-5:  #FFD980;
    --ad-warning-shade-5: #805A00;
    --ad-warning-disabled:#efe5cb;
    --ad-warning-subtle:  #fdf0cf;

    /*  Info (contrast)  */ 
    --ad-info:         #8A56BF;
    --ad-info-tint-1:  #9768C7;
    --ad-info-shade-1: #7E52B1;
    --ad-info-tint-2:  #A479CE;
    --ad-info-shade-2: #7149A4;
    --ad-info-tint-3:  #B08FCC;
    --ad-info-shade-3: #684A8E;
    --ad-info-tint-4:  #BE9ED7;
    --ad-info-shade-4: #5B407F;
    --ad-info-tint-5:  #C8A9DD;
    --ad-info-shade-5: #543A6F;
    --ad-info-disabled:#d9cde3;
    --ad-info-subtle:  #e6cffc;

    /*
    Original: #6B1FB1 → Lighter/Muted: #8A56BF
    Original: #7A35B9 → Lighter/Muted: #9768C7
    Original: #601C9F → Lighter/Muted: #7E52B1
    Original: #894CC1 → Lighter/Muted: #A479CE
    Original: #56198E → Lighter/Muted: #7149A4
    Original: #9762C8 → Lighter/Muted: #B08FCC
    Original: #4B167C → Lighter/Muted: #684A8E
    Original: #A679D0 → Lighter/Muted: #BE9ED7
    Original: #40136A → Lighter/Muted: #5B407F
    Original: #B58FD8 → Lighter/Muted: #C8A9DD
    Original: #361058 → Lighter/Muted: #543A6F
    Original: #d9cde3 → Lighter/Muted: #E2D9E9
    Original: #e6cffc → Lighter/Muted: #EBDBFE
*/
}

/* Custom button styles */
.btn.btn-ad-primary {
    background-color: var(--ad-primary-tint-3);
}
.btn.btn-ad-primary:hover {
    background-color: var(--ad-primary);
}
.btn.btn-ad-primary:disabled {
    background-color: var(--ad-primary-disabled);
    border-color: var(--ad-primary-tint-5);
}

.btn.btn-ad-secondary {
    background-color: var(--ad-secondary-tint-1);
}
.btn.btn-ad-secondary:hover {
    background-color: var(--ad-secondary-shade-1);
}
.btn.btn-ad-secondary:disabled {
    background-color: var(--ad-secondary-disabled);
    border-color: var(--ad-secondary-tint-5);
}

.btn.btn-ad-success {
    background-color: var(--ad-success-tint-3);
    color: white;
}
.btn.btn-ad-success:hover {
    background-color: var(--ad-success);
    color: white;
}
.btn.btn-ad-success:disabled {
    background-color: var(--ad-success-disabled);
    border-color: var(--ad-success-tint-5);
}

.btn.btn-ad-warning {
    background-color: var(--ad-warning-tint-3);
}
.btn.btn-ad-warning:hover {
    background-color: var(--ad-warning);
}
.btn.btn-ad-warning:disabled {
    background-color: var(--ad-warning-disabled);
    border-color: var(--ad-warning-tint-5);
}

.btn.btn-ad-danger {
    background-color: var(--ad-danger);
    color: white;
}
.btn.btn-ad-danger:hover {
    background-color: var(--ad-danger-shade-2);
    color: white;
}
.btn.btn-ad-danger:disabled {
    background-color: var(--ad-danger-disabled);
    border-color: var(--ad-danger-tint-5);
}

.btn.btn-ad-info {
    background-color: var(--ad-info-tint-3);
    color: white;
}
.btn.btn-ad-info:hover {
    background-color: var(--ad-info);
    color: white;
}
.btn.btn-ad-info:disabled {
    background-color: var(--ad-info-disabled);
    border-color: var(--ad-info-tint-5);
}

.accordion-button:not(.collapsed) {
    background-color: var(--ad-primary-tint-5)!important;
}

nav.navbar {
    background-color: var(--ad-secondary)!important;
}
  
.item:hover {
    cursor:pointer;
}
  
.item:hover > td {
    background-color: var(--orange-lt-color);
}
  
.project-item:hover {
    background-color: var(--orange-lt-color);
    cursor:pointer;
}

.bg-ad-danger {
    background-color: var(--ad-danger-shade-1);
}

.bg-ad-warning {
    background-color: var(--ad-warning-tint-2)!important;
}

.bg-ad-info {
    background-color: var(--ad-info-tint-5)!important;
}

.bg-ad-secondary {
    background-color: var(--ad-secondary-shade-3)!important;
}

.bg-ad-primary {
    background-color: var(--ad-primary-tint-4)!important;
}

.alert.alert-ad-primary {
    color: var(--ad-primary-shade-5);
    background-color: var(--ad-primary-subtle);
    border-color: var(--ad-primary-shade-2);
}
.alert.alert-ad-secondary {
    color: var(--ad-secondary-shade-5);
    background-color: var(--ad-secondary-subtle);
    border-color: var(--ad-secondary-shade-3);
}
.alert.alert-ad-success {
    color: var(--ad-success-shade-3);
    background-color: var(--ad-success-subtle);
    border-color: var(--ad-success-shade-1);
}
.alert.alert-ad-danger {
    color: var(--ad-danger-shade-5);
    background-color: var(--ad-danger-subtle);
    border-color: var(--ad-danger-shade-2);
}
.alert.alert-ad-warning {
    color: var(--ad-warning-shade-5);
    background-color: var(--ad-warning-subtle);
    border-color: var(--ad-warning-shade-2);
}
.alert.alert-ad-info {
    color: var(--ad-info-shade-3);
    background-color: var(--ad-info-subtle);
    border-color: var(--ad-info);
}

.nav-link.text-ad-primary {
    color: var(--ad-primary-shade-1);
}
.nav-link.text-ad-primary:hover {
    color: var(--ad-primary-shade-3);
}
.nav-link.text-ad-secondary {
    color: var(--ad-secondary-shade-1);
}
.nav-link.text-ad-success {
    color: var(--ad-success-shade-1);
}
.nav-link.text-ad-danger {
    color: var(--ad-danger);
}
.nav-link.text-ad-danger:hover {
    color: var(--ad-danger-shade-2);
}
.nav-link.text-ad-warning {
    color: var(--ad-warning-shade-1);
}
.nav-link.text-ad-info {
    color: var(--ad-info-shade-1);
}

td > span.empty {
    color: var(--ad-secondary-shade-1);
    font-style: italic;
    font-weight: 500;
}

table.table > thead > tr > th {
    color: var(--ad-secondary-shade-5);
    font-weight: 750;
}

table.table > thead > tr > th {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

nav.breadcrumb {
    margin-bottom: 0;
    padding: 0px 0px !important;
}

ol.breadcrumb {
    margin-bottom: 0;
    padding: 0!important;
}

.breadcrumb-item {
    /* font-size: 18pt; */
    /* font-size: calc(1.325rem + .9vw); */
    font-size: calc(1.3rem + .6vw);
    font-weight: 600;
}

.breadcrumb-item:hover {
    color: var(--ad-danger-shade-2)!important;
    text-decoration: underline;
    cursor: pointer;
}

.breadcrumb-item.active {
    color: var(--ad-primary-shade-2)!important;
    text-decoration: none!important;
    cursor: default!important;
    font-weight: 700;
}